@import '../../../node_modules/monday-ui-react-core/src/styles/themes.scss';

.replies-list {
  border-top: 1px solid;
  @include theme-prop(border-color, layout-border-color);

  &_item {
    padding: 14px 15px 7px;
    display: flex;
    gap: 8px;
  }

  &_body {
    background-color: var(--dark-background-color);
    color: var(--primary-text-color);
    border-radius: 16px;
    padding: 10px 16px;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    position: relative;
    line-height: 20px;
    font-size: 16px;
    font-size: var(--font-size-general-label);
    display: inline;
    word-wrap: break-word;
    word-break: break-all;

    p {
      margin: 2px 0;
    }
  }

  &_name {
    font-weight: 400;
    vertical-align: top;
    margin-right: 3px;
    color: var(--primary-color);
    white-space: nowrap;
  }

  &_aside {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    word-wrap: break-word;
  }
}
