@import '../../../node_modules/monday-ui-react-core/src/styles/themes.scss';

.message {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  justify-content: center;
  margin-top: 100px;

  padding: 20px;
  text-align: center;
}

.error {
  @include theme-prop(color, negative-color);
}
