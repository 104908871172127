@import '../../../node_modules/monday-ui-react-core/src/styles/themes.scss';

.app {
  height: 100vh;
  @include theme-prop(color, primary-text-color);
  margin: 0 auto;
  max-width: 750px;
  padding: 20px;
}

a {
  color: var(--link-color);
}
