.person-filter {
  position: relative;
  width: max-content;

  &_dialog-container {
    width: 340px;
    position: absolute;
    z-index: 2;
    right: 0;
  }

  &_selected {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
  }

  &_button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
  }

  .monday-style-counter {
    padding-left: 0;
    padding-right: 0;
  }
}
.monday-style-tooltip {
  .monday-style-avatar_circle {
    border: none;
  }
}
