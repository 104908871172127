@import '../../../node_modules/monday-ui-react-core/src/styles/themes.scss';

.post {
  border: 1px solid;
  @include theme-prop(border-color, layout-border-color);
  width: 100%;

  overflow-y: hidden;

  position: relative;
  border-radius: 4px;

  &_body {
    padding: 8px 16px 16px 16px;
    word-wrap: break-word;

    p {
      margin: 0;
      margin-bottom: 5px;
    }
  }

  &_button-wrapper {
    background-image: linear-gradient(
      to bottom,
      rgba(var(--primary-background-color-rgb), 0) 0%,
      rgba(var(--primary-background-color-rgb), 1) 70%
    );
    position: absolute;
    width: 100%;
    height: 120px;
    bottom: 0;
    transition: height 0.14s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    .post_button {
      margin-top: 30px;
      color: var(--primary-color);

      &:hover {
        background-color: var(--primary-color) !important;
        color: var(--text-color-on-primary);
      }
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 16px 8px 16px;

    &_person {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 8px;
    }

    &_metadata {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
