.time {
  font-weight: 400;
  line-height: 16px;
  font-size: var(--font-size-general-label);

  width: max-content;

  display: flex;
  align-items: center;
  gap: 2px;

  &:hover {
    cursor: pointer;
  }
}
