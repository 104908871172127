.date-filter {
  position: relative;
  width: max-content;

  &_dialog-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    right: 0;
  }

  &_reset {
    align-self: flex-end;
    padding: 4px;
    color: var(--link-color);

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  //fix number position in monday date component
  .CalendarDay__default {
    padding: 6px;
  }
}
